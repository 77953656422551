<template>
  <div class="About">
    <div
        v-for="(info, index) in mergeArrays"
        :key="index"
        class="About-Item"
    >
      <img :src="require(`@/assets/images/${info.num}`)" alt="number">
      <div class="About-ItemInfo">
        <span>{{ info.title }}</span>
        <p>{{ info.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      infos: [
        {num: "one.svg"},
        {num: "two.svg"},
        {num: "three.svg"},
      ],
    }
  },
  computed: {
    mergeArrays() {
      let mergeArr = this.infos.map((info, index) => ({...info, ...this.$t('about')[index]}));
      return mergeArr
    }
  }
}
</script>

<style lang="scss">
.About {
  position: relative;
  bottom: -56px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 288px;
  margin: 0 auto;
  padding: 24px 28px 20px 24px;
  background: linear-gradient(259.75deg, #252C45 0.54%, #353B52 100%);
  box-shadow: inset 0px -6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  @media (min-width: $screen-s) {
    align-items: stretch;
    gap: 12px;
    bottom: 0;
    width: 302px;
    margin: 0 0 25px;
    padding: 0;
    background: none;
    box-shadow: none;
    border-radius: 0;
  }

  @media (min-width: $screen-m) {
    width: 450px;
    margin: 0 0 18px 0;
  }

  @media (min-width: $screen-l) {
    flex-direction: row;
    width: 100%;
  }

  @media (min-width: $screen-xl) {
    margin-bottom: 25px;
  }

  &-Item {
    display: flex;
    justify-content: start;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    @media (min-width: $screen-s) {
      padding: 25px 14px 25px 28px;
      background: linear-gradient(259.75deg, #252C45 0.54%, #353B52 100%);
      box-shadow: inset 0px -6px 20px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    @media (min-width: $screen-l) {
      flex: 0 0 268px;
      padding: 16px;
    }

    @media (min-width: $screen-l) {
      flex: 0 0 302px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    img {
      margin-right: 20px;
      @media (min-width: $screen-s) {
        filter: drop-shadow(0px 0px 50px rgba(255, 255, 255, 0.5));
      }
    }

    span {
      margin-bottom: 2px;
      font-weight: 700;
      font-size: 23px;
      line-height: 1.4;
      color: var(--color-text-main);

      @media (min-width: $screen-l) {
        font-size: 20px;
      }

      @media (min-width: $screen-xxl) {
        font-size: 23px;
      }
    }

    p {
      font-weight: 500;
      font-size: 10px;
      line-height: 1.6;
      color: var(--color-text-grey);

      @media (min-width: $screen-m) {
        font-size: 12px;
      }
    }
  }
}

</style>