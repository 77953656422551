<template>
    <footer class="Footer">
      <div class="Wrapper Footer-Wrapper">
      <div class="Footer-Licence">
        <img v-for="(licence, index) in licences"
             :key="index"
             :src="require(`@/assets/images/${licence}`)"
             alt=""
        >
      </div>
      <Payments class="Footer-Payments"/>
      </div>
    </footer>
</template>

<script>
import {getParams} from '../assets/utils/getParams'
import Payments from "./Payments";

export default {
  name: "Footer",
  components: {Payments},
  data() {
    return {
      licences: [
        "curacao.svg",
        "ic_18Plus.svg",
        "ic_ssl.svg",
      ],
      apps: [
        {icon: "android.svg"},
        {icon: "apple.svg"},
      ]
    }
  },
  computed: {
    mergeArrays() {
      let mergeArr = this.apps.map((info, index) => ({...info, ...this.$t('footer.app')[index]}));
      return mergeArr
    }
  },
  methods: {
    getParams,
  },
}
</script>

<style lang="scss">
.Footer {
  padding: 72px 0 95px;
  background: #060E2A;

  @media (min-width: $screen-s) {
    position: relative;
    bottom: 0;
    padding: 20px 0;
    background: none;
  }

  @media (min-width: $screen-xxl) {

  }

  &-Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $screen-s) {
      flex-direction: row;
      flex-wrap: nowrap;
    }

  }

  &-Licence {
    display: flex;
    align-items: center;

    @media (min-width: $screen-s) {
    }

    > img {
      &:first-child {
        margin-right: 40px !important;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }

      @media (min-width: $screen-s) {
        &:first-child {
          margin-right: 27px !important;
        }
      }
    }
  }

  &-Payments {
    display: none;
    @media (min-width: $screen-s) {
      display: flex;
    }
  }
}

</style>