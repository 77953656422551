<template>
  <div class="Home">
    <Header/>
    <Payments class="Home-Payments"/>
    <Games class="Home-Games"/>
    <About class="Home-About"/>
    <Footer class="Home-Footer"/>
  </div>
</template>

<script>

import Header from "@/components/Header";
import Payments from "@/components/Payments";
import Games from "../components/Games";
import About from "../components/About";
import Footer from "@/components/Footer";

export default {
  name: 'Home',
  components: {
    About,
    Games,
    Footer,
    Payments,
    Header
  }
}
</script>

<style lang="scss">
.Home {
  overflow-x: hidden;

  @media (min-width: $screen-s) {
    background-image: url(~@/assets/images/header-bg.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: auto;
  }

  &-Payments,
  &-Games,
  &-About,
  &-Footer {
    @media (min-width: $screen-s) {
      display: none;
    }
  }
}
</style>
