<template>
  <div class="Games">
    <div class="Games-Cards">
      <a
          v-for="(card, i) in cards"
          :key="i"
          :href="`https://ninecasinogo.com/${getAllParams()}`"
          class="Games-Card"
      >
        <div>
          <img :src="require(`@/assets/images/${card.img}`)" alt="game image">
        </div>
        <p>
          {{ card.title }}
        </p>
      </a>
    </div>
    <a :href="`https://ninecasinogo.com/${getAllParams()}`" class="Games-Games">
      {{ $t('games') }}
    </a>
  </div>
</template>

<script>
import {getAllParams} from "../assets/utils/getParams";

export default {
  name: "Games",
  data() {
    return {
      cards: [
        {img: 'book-of-dead.png', title: 'Book of Dead',},
        {img: 'wolf-gold.png', title: 'Wolf Gold',},
        {img: 'gates-of-olympus.png', title: 'Gates of Olympus',},
        {img: 'legasy-of-dead.png', title: 'Legacy of Dead',},
        {img: 'sweet-bonanza.png', title: 'Sweet Bananza',},
      ]
    }
  },
  methods: {
    getAllParams,
  }
}
</script>

<style lang="scss">
.Games {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -43px;
  padding: 10px 0;

  @media (min-width: $screen-s) {
    flex-direction: row;
    margin-bottom: 40px;
    padding: 0;
  }

  &-Cards {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin-bottom: 16px;
    padding: 0 16px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: $screen-s) {
      width: auto;
      margin-bottom: 0;
      padding: 0;
      overflow-x: visible;
    }
  }

  &-Card {
    display: flex;
    flex: 0 0 90px;
    flex-direction: column;
    align-items: center;
    align-self: start;
    background: #161D37;
    border-radius: 8px;

    > div {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;

      &:before {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        border-radius: 8px 8px 0 0;
      }
    }

    > p {
      display: block;
      width: 82px;
      padding: 4px 0;
      font-weight: 500;
      font-size: 10px;
      line-height: 160%;
      text-align: center;
      color: #D0D2D7;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-Games {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 79px;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    color: var(--color-text-main);
    text-align: center;
    border: 2px solid #444A5F;
    border-radius: 50px;

    @media (min-width: $screen-s) {
      width: 116px;
      height: 116px;
      padding: 0;
      margin-left: 55px;
      border-radius: 50%;
    }
  }
}
</style>
