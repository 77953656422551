export function getParams() {
    let params = window.location.search;
    let partsQ;
    let partsAmp;
    let keyword = "";
    if (params) {
        partsQ = params.split("?");
        partsAmp = partsQ[1].split("&")
        partsAmp.forEach(el => {
                let parts = el.split("=") || '';
                if (parts[0] === "par" || parts[0] === "PAR") {
                    keyword = parts[1];
                }
            }
        )
    }
    return keyword;
}

export function getAllParams() {
    const params = window.location.search

    return params ? params + '&open=registration' : '?open=registration'
}
