<template>
  <div class="Payments">
    <div>
      <img
          v-for="(icon, index) in icons"
          :key="index"
          :src="require(`@/assets/images/${icon.icon}`)"
          alt="payment icon"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Payments",
  data() {
    return {
      icons: [
        {
          icon: "visa.svg",
        },
        {
          icon: "skrill.svg",
        },
        {
          icon: "banktransfer.svg",
        },
        {
          icon: "mifinity.svg",
        },
        {
          icon: "mastercard.svg",
        },
        {
          icon: "banking.svg",
        },
        {
          icon: "bitcoin.svg",
        },
        {
          icon: "paysafecard.svg",
        },
      ]
    }
  },
}
</script>

<style lang="scss">
.Payments {
  display: flex;
  justify-content: center;
  background: #252C45;

  @media (min-width: $screen-s) {
    padding: 0;
    background: none;
  }

  > div {
    display: flex;
    gap: 0 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 320px;
    width: 100%;
    padding: 0 16px;

    @media (min-width: $screen-s) {
      flex-wrap: nowrap;
      gap: 20px;
      max-width: 100%;
      padding: 0;
    }

    @media (min-width: $screen-m) {
      gap: 42px;
    }
  }

}
</style>