<template>
  <header
      class="Header"
      :class="{'Header--fixed': documentIsScrolled}"
  >
    <div class="Wrapper">
      <a
          :href="`https://ninecasinogo.com/${getAllParams()}`"
          class="Header-Logo"
      >
        <img src="@/assets/images/logo.svg" alt="logo">
      </a>
      <div class="Header-Content">
        <p v-html="$t('header.aboveTitle')"></p>
        <h1 v-html="$t('header.title')"></h1>
        <span v-html="$t('header.text')"></span>
        <img
            src="@/assets/images/sofort.svg"
            alt="sofort logo"
        >
      </div>
      <a
          :href="`https://ninecasinogo.com/${getAllParams()}`"
          class="Header-Btn"
      >
        <span>
          {{ $t('button.title') }}
        </span>
        <span>
          {{ $t('button.text') }}
        </span>
      </a>
      <About class="Header-About"/>
      <Games class="Header-Games"/>
    </div>
    <img src="@/assets/images/hand.png" alt="hand">
    <Footer class="Header-Footer"/>
  </header>
</template>

<script>
import {getAllParams} from "../assets/utils/getParams";
import About from "./About";
import Games from "./Games";
import Footer from "./Footer";

export default {
  name: "Header",
  components: {Footer, Games, About},
  data() {
    return {
      documentIsScrolled: false,
    }
  },
  methods: {
    getAllParams,
    onScroll() {
      this.documentIsScrolled = window.scrollY > 0;
    },
  },
  mounted() {
    if (window.scrollY > 0) this.documentIsScrolled = true;
    window.addEventListener('scroll', this.onScroll, { passive: true });
  },
}
</script>

<style lang="scss">
.Header {
  position: relative;
  padding: 20px 0 16px;
  background-image: url(~@/assets/images/header-bg-mobile.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: $screen-s) {
    height: 100vh;
    padding: 36px 0 0;
    background-image: none;
    overflow-x: hidden;
  }

  &--fixed {
    .Header-Content {
      @media (max-width: 959px) {
        margin-bottom: 85.2px;
      }
    }
    .Header-Btn {
      @media (max-width: 959px) {
        position: fixed;
        bottom: 20px;
        z-index: 10;
        transition: 3s;
      }
    }
  }

  &-Logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 36px;

    @media (min-width: $screen-s) {
      justify-content: flex-start;
      margin: 0 0 18px;
    }

    @media (min-width: $screen-xxl) {
      width: 328px;
      margin: 0 0 59px;
      > img {
        width: 100%;
      }
    }
  }

  &-Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    text-align: center;

    @media (min-width: $screen-s) {
      align-items: flex-start;
      margin-bottom: 16px;
      text-align: left;
    }

    @media (min-width: $screen-l) {
      margin: 0 0 16px;
    }

    @media (min-width: $screen-xxl) {
      margin: 0 0 32px;
    }

    > p {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.2;
      color: var(--color-text-main);
      opacity: 0.9;

      @media (min-width: $screen-s) {
        margin-bottom: 32px;
        font-size: 20px;
      }

      @media (min-width: $screen-l) {
        margin: 0 0 40px;
      }

      @media (min-width: $screen-xl) {
        margin-bottom: 32px;
        font-size: 28px;
      }

      @media (min-width: $screen-xl) {
        margin-bottom: 38px;
        font-size: 32px;
      }
    }

    > h1 {
      margin-bottom: 8px;
      font-weight: 800;
      font-size: 76px;
      line-height: .8;
      color: var(--color-text-main);

      @media (min-width: $screen-s) {
        margin-bottom: 16px;
        font-size: 100px;
      }

      @media (min-width: $screen-l) {
        margin-bottom: 16px;
        font-size: 115px;
      }

      @media (min-width: $screen-xl) {
        margin-bottom: 8px;
        font-size: 135px;
      }

      @media (min-width: $screen-xxl) {
        margin-bottom: 14px;
        font-size: 175px;
      }
    }

    > span {
      font-weight: 800;
      font-size: 24px;
      line-height: 1.2;
      color: var(--color-text-orange);
      text-transform: uppercase;

      @media (min-width: $screen-s) {
        font-size: 27px;
      }

      @media (min-width: $screen-l) {
        font-size: 38px;
      }

      @media (min-width: $screen-xl) {
        font-size: 42px;
      }

      @media (min-width: $screen-xl) {
        font-size: 50px;
      }
    }

    > img {
      @media (min-width: $screen-s) {
        display: none;
      }
    }
  }

  &-Btn {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    width: 288px;
    padding: 10px 61px;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    text-align: center;
    color: var(--color-text-dark);
    background: radial-gradient(70.57% 75.64% at 22.42% 36.2%, #FCE439 0%, #FEB502 100%);
    border-radius: 50px;

    @media (min-width: $screen-s) {
      left: 0;
      margin-bottom: 36px;
      transform: translate(0, 0);
    }

    @media (min-width: $screen-xxl) {
      margin-bottom: 64px;
      font-size: 23px;
    }

    span {
      display: block;
      &:last-child {
        font-weight: 500;
        font-size: 10px;
        line-height: 1.6;

        @media (min-width: $screen-xxl) {
          font-size: 12px;
        }
      }
    }
  }

  > img {
    display: none;
    @media (min-width: $screen-s) {
      display: block;
      position: absolute;
      right: -32px;
      top: 27%;
      width: 611px;
      pointer-events: none;
    }

    @media (min-width: $screen-l) {
      top: 3%;
      right: 0;
    }

    @media (min-width: $screen-xl) {
      width: 737px;
    }

    @media (min-width: $screen-xxl) {
      top: 7%;
      width: 912px;
    }
  }

  &-About,
  &-Games,
  &-Footer {
    display: none;
    @media (min-width: $screen-s) {
      display: flex;
    }
  }
}

</style>
